import React from 'react';
import './main.css';
import Header from './header';
import Center from './center';


function Main() {
    return (
        <div className="main">
            <div className="top-header">
                <Header/>
            </div>
            <div className="center">
                <Center />
            </div>
        </div>
    );
}

export default Main;