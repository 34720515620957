import React from 'react';
import './center.css';
import About from './about';
import Skill from './skill';
import Project from './project';
import History from './history';

function Center() {
    return (
        <body>
            <div className="about">
                <About/>
            </div>
            <div className="skill">
                <Skill/>
            </div>
            <div className="project">
                <Project/>
            </div>
            <div className="history">
                <History/>
            </div>
        </body>
    )
}

export default Center;