import React from 'react';
import './about.css'
import myImg from "../resource/img/my.jpg";

function About() {
    return (
        <div id="about">
            <div className="card-header">
                <h1>ABOUT ME</h1>
            </div>
            <div className="w-100">
                <div className="dot">
                    <p>·</p>
                    <p>·</p>
                    <p>·</p>
                </div>
                <div className="w-100 d-flex justify-content-center">
                    <div className="aboutme">
                        <div className="profile-img">
                            <img className="myImg" src={myImg}/>
                        </div>
                        <div className="details">
                            <div className="info">
                                <div className="label">
                                    <p>이름</p>
                                </div>
                                <div className="value">
                                    <p>주학수</p>
                                </div>
                            </div>
                            <div className="info">
                                <div className="label">
                                    <p>생년월일</p>
                                </div>
                                <div className="value">
                                    <p>1987.03.15</p>
                                </div>
                            </div>
                            <div className="info">
                                <div className="label">
                                    <p>성별</p>
                                </div>
                                <div className="value">
                                    <p>남자</p>
                                </div>
                            </div>
                            <div className="info">
                                <div className="label">
                                    <p>이메일</p>
                                </div>
                                <div className="value">
                                    <p>wiz47@daum.net</p>
                                </div>
                            </div>
                            <div className="info">
                                <div className="label">
                                    <p>주소</p>
                                </div>
                                <div className="value">
                                    <p>제주특별자치도 제주시 오복2길 27</p>
                                </div>
                            </div>
                            <div className="info">
                                <div className="label">
                                    <p>연락처</p>
                                </div>
                                <div className="value">
                                    <p>010-9210-1480</p>
                                </div>
                            </div>
                            <div className="info">
                                <div className="label">
                                    <p>학력</p>
                                </div>
                                <div className="value">
                                    <p>신한대학교(구 신흥대학) 웹프로그래밍 전공(3년)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="career-info">
                    <div className="career-detail">
                        <div className="my-career">
                            <h3>[MY Career]</h3>
                            <p>
                                ∙ 웹프로그래밍 전공으로 신흥대학에서 학사학위를 받았습니다.
                            </p>
                            <p>
                                ∙ 웹개발자로써 2년 3개월 동안 이룸컴퍼니에서 주임연구원으로 일하며 백엔드와 프론트엔드 개발 및 유지보수 업무를 수행했습니다.
                            </p>
                            <p>
                                ∙ 주요 기술 스택으로는 jQuery, Maria DB, Thymeleaf, Spring Boot, Ajax, Oracle DB, JavaScript, Java,
                                Bootstrap
                                등을 사용했습니다.
                            </p>
                            <br/>
                            <h3>[핵심역량]</h3>
                            <p>
                                ∙ JDC면세점 홈페이지 운영 유지보수
                            </p>
                            <p>
                                ∙ 장정맥 프로젝트 휴대폰번호 인증 개발
                            </p>
                            <p>
                                ∙ JDC 복지앱 개발 및 유지보수
                            </p>
                            <p>
                                ∙ 랜딩페이지 개발
                            </p>
                            <p>
                                ∙ 제제골프 웹 관리자 페이지 개발
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;