import React from 'react';
import './history.css'

function About() {
    return (
        <div id="history">
            <div className="card-header">
                <h1>HISTORY</h1>
            </div>
            <div className="dot">
                <p>·</p>
                <p>·</p>
                <p>·</p>
            </div>
            <div className="title"></div>
            <div className="history-list">
                <div className="history-detail">
                    <div className="horizontal"></div>
                    <div className="w-100 d-block">
                        <div className="history">
                            <h3>이룸컴퍼니</h3>
                            <p>
                                2021. 10 ~ 2023. 12
                            </p>
                            <p>
                                교육 이수 후, 바로 취업활동을 하여 입사했습니다.
                            </p>
                            <p>
                                담당 업무 : JDC인터넷 면세점 유지보수와 모두의 티켓 유지보수,
                                랜딩 페이지 제작, 바이오(장정맥)인증 프로젝트(기존 개발이 되어있는 고객사에 추가 개발로 참여), 골프 예약 앱 관리자 페이지 제작.
                            </p>
                            <p>
                                ∙ 사용툴 : Eclipse, IntelliJ
                            </p>
                            <p>
                                ∙ 언어 : Java, JavaScript
                            </p>
                            <p>
                                ∙ DB : MariaDB, Oracle
                            </p>
                        </div>
                        <div className="line history-line"></div>
                        <div className="history">
                            <h3>휴먼교육센터</h3>
                            <p>
                                2021. 3 ~ 2021. 9
                            </p>
                            <p>
                                내일배움카드 발급하여 국비지원을 받아 6개월간 Java 교육을 받았습니다.
                            </p>
                            <p>
                                ∙ 사용툴 : Eclipse, VSCode
                            </p>
                            <p>
                                ∙ 언어 : Java, JavaScript
                            </p>
                            <p>
                                ∙ DB : MySql, Oracle
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;