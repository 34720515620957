import React from 'react';
import './skill.css'
import java from "../resource/img/skill/java.png";
import bootstrap from "../resource/img/skill/bootstrap.png";
import docker from "../resource/img/skill/docker.png";
import git from "../resource/img/skill/git.png";
import html from "../resource/img/skill/html.png";
import javascript from "../resource/img/skill/javascript.png";
import jquery from "../resource/img/skill/jquery.png";
import mariadb from "../resource/img/skill/mariadb.png";
import mysql from "../resource/img/skill/mysql.png";
import oracle from "../resource/img/skill/oracle.png";
import springboot from "../resource/img/skill/springboot.png";
import thymeleaf from "../resource/img/skill/thymeleaf.png";

function Skill() {
    return (
        <div id="skill">
            <div className="card-header">
                <h1>SKILL</h1>
            </div>
            <div className="dot">
                <p>·</p>
                <p>·</p>
                <p>·</p>
            </div>
            <div className="title"></div>
            <div className="w-100 d-flex justify-content-center">
                <div className="card">
                    <div className="card-body w-100 d-flex justify-content-center">
                        <div className="skill">
                            <img src={java}/>
                            <img src={javascript}/>
                            <img src={springboot}/>
                            <img src={html}/>
                        </div>
                    </div>
                    <div className="card-body w-100 d-flex justify-content-center">
                        <div className="skill">
                            <img src={oracle}/>
                            <img src={mariadb}/>
                            <img src={mysql}/>
                            <img src={jquery}/>
                        </div>
                    </div>
                    <div className="card-body w-100 d-flex justify-content-center">
                        <div className="skill">
                            <img src={bootstrap}/>
                            <img src={docker}/>
                            <img src={git}/>
                            <img src={thymeleaf}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skill;