import React from 'react';
import './project.css'
import lancome1 from "../resource/img/lancome-1.jpg";
import jdc from "../resource/img/jdc.jpg";
import modu1 from "../resource/img/modu-1.png";
import modu2 from "../resource/img/modu-2.png";
import golf1 from "../resource/img/golf-1.jpg";
import golf2 from "../resource/img/golf-2.jpg";
import golf3 from "../resource/img/golf-3.jpg";
import golf4 from "../resource/img/golf-4.jpg";

function About() {
    return (
        <div id="project">
            <div className="card-header">
                <h1>PROJECT</h1>
            </div>
            <div className="w-100">
                <div className="dot">
                    <p>·</p>
                    <p>·</p>
                    <p>·</p>
                </div>
                <div className="project-title">
                    <h2>JDC 인터넷 면세점 운영 유지보수</h2>
                </div>
                <div className="lancome w-100 d-flex justify-content-center">
                    <div className="lancome-details">
                        <div className="lancome-detail">
                            <h3>[랑콤 공식스토어 추가]</h3>
                            <img className="item-img" src={lancome1}/>
                            <p>랑콤 공식스토어 신규 페이지 작업</p>
                            <p>목록에서 색상 슬라이드 및 선택 시 해당 상품으로 변경</p>
                            <p>상품 페이지에서 색상 및 용량 변경 시 상품 이동</p>
                        </div>
                        <div className="lancome-detail">
                            <h3>[오류 등 수정 작업]</h3>
                            <img className="item-img" src={jdc}/>
                            <p>검색 오류 수정</p>
                            <p>적용되지 않는 기능 수정</p>
                            <p>필터 기능 오류 수정</p>
                            <p>슬라이드 오류 수정</p>
                            <p>등등...</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-100 margin-top-10">
                <div className="d-flex justify-content-center">
                    <div className="line"></div>
                </div>
                <div className="project-title">
                    <h2>모두의 티켓</h2>
                </div>
                <div className="jdc">
                    <div className="jdc-detail">
                        <img className="jdc-img" src={modu1}/>
                        <img className="jdc-img" src={modu2}/>
                        <div className="jdc-info">
                            <h3>[소개]</h3>
                            <p>JDC 면세점 직원용 복지 포인트 앱</p>
                            <br/>
                            <h3>[핵심 기능]</h3>
                            <p>- 포인트 충전 및 사용</p>
                            <p>- 특정 시간 출근에 따라 다른 포인트 충전</p>
                            <p>- 한달 동안 모인 교통 포인트는 교통카드로 충전하여 사용 가능(관리자가 직접 충전용 코드 발송)</p>
                            <p>- 식권 포인트는 언제든지 가맹점에서 사용 가능</p>
                            <p>- 가맹점용 모두의 티켓 박스 앱으로 결제 관리 가능</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-100 margin-top-10">
                <div className="d-flex justify-content-center">
                    <div className="line"></div>
                </div>
                <div className="project-title">
                    <h2>골프 예약 앱 – 관리자 페이지</h2>
                </div>
                <div className="golf">
                    <div className="golf-detail">
                        <div className="golf-img-list">
                            <img className="golf-img" src={golf1}/>
                            <img className="golf-img" src={golf2}/>
                        </div>
                        <div className="golf-img-list">
                            <img className="golf-img" src={golf3}/>
                            <img className="golf-img" src={golf4}/>
                        </div>
                        <div className="d-block w-100">
                            <h3>[소개]</h3>
                            <p>제주도 내 골프장 예약 어플을 관리하는 페이지</p>
                            <br/>
                            <h3>[핵심 기능]</h3>
                            <p>- 회원 정보 확인 및 비밀번호 초기화</p>
                            <p>- 공지사항 등록, 수정, 삭제(파일 등록 및 삭제)</p>
                            <p>- 골프장 등록, 수정, 삭제(썸네일 등록 및 썸네일 순서 변경 가능)</p>
                            <p>- 골프장 상품 등록, 수정, 삭제(시간대 별 금액을 다르게 등록할 수 있고, 서식에 맞게 엑셀로 작성 후 엑셀파일을 불러와서 등록 가능)</p>
                            <p>- 어플 메인의 배너 등록, 수정, 삭제</p>
                            <p>- 어플 메인의 테마 등록, 수정, 삭제.(테마에 문구 등록 시 색상, 굵기 설정)</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;