import React from 'react';
import './header.css';

function Header() {
    return (
        <header>
            <div className="header">
                <div className="header-home">
                    <div className="home">
                        <span className="header-text">백엔드 개발자 주학수의 포트폴리오</span>
                    </div>
                    <div className="menu">
                        <a href="#about">AboutMe</a>
                        <a href="#skill">Skill</a>
                        <a href="#project">Project</a>
                        <a href="#history">History</a>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;